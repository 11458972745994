import '../scss/styles.scss';
import '@babel/polyfill';
import queryString from 'query-string';
import './jquery-global';
import smoothscroll from 'smoothscroll-polyfill';
import { Timezone } from './timezone';
import { sideMenuManager } from './side-menu';
import { footerContactManager } from './footer-contact';
import { StorageButtonManager } from './storage-button-manager';
import { Keep } from './keep';
import { Favorite } from './favorite';
import { Collapse } from './collapse';
import {
  NotificationModal,
  RegionModal,
  SimpleModal,
  SelectAttachDocumentMessageModal,
  DeleteDocumentModal,
  RenameDocumentModal,
  ApplyJobOfferDetailModal,
  IndeedEntryModal,
  RenameSearchConditionModal,
  DeleteSearchConditionModal,
  HideMessageModal,
  SearchStateModal,
} from './modules/modal';
import { HeadRoom } from './head-room';
import { FootBar } from './foot-bar';
import { ReadMore } from './read-more';
import { Accordion } from './accordion';
import { Tab } from './tab';
import { GridTab } from './grid-tab';
import { DateOptionsGenerator } from './date-options-generator';
import { CityOptionsGenerator } from './city-options-generator';
import { AddressFillFromPostalCode } from './address-fill-from-postalcode';
import { OnetimeDisplaySwitcher } from './onetime-display-switcher';
import { RefineSearch } from './refine-search';
import { AsyncJobOfferAggregation } from './async-job-offer-aggregation';
import { ScrollToTop, ScrollToReadStart, ScrollToTarget } from './scroll-to';
import { SearchConditionSaveForm } from './search-condition-save-form';
import { MessageForm } from './message-form';
import { FormManager } from './form-manager';
import { LinkExtender } from './link-extender';
import { RequiredQualificationByJobCategory } from './required-qualification-by-job-category';
import { CheckboxSynchronizer } from './checkbox-synchronizer';
import { Toggle } from './toggle';
import { Toaster } from './toaster';
import { Dropdown } from './dropdown';
import { MemberNotification } from './member-notification';
import { InputtedTextCounter } from './inputted-text-counter';
import { LazyImage } from './lazy-image';
import { emitter } from './util/event-emitter';
import { isIOS } from './util/device';
import { RestartGuidanceManager } from './restart-guidance-manager';
import { IOSDrumrollOmissionPreventer } from './ios-drumroll-omission-preventer';

// jquery-global.js でwindowに生やしたjQueryを参照する
const $ = window.jQuery;

sideMenuManager();
// footer
footerContactManager();

class App {
  constructor() {
    this.refineSearches = [...document.querySelectorAll('.js-refine-search')].map((el) => {
      if ($(el).closest('#js-map-search').length) {
        return null;
      }
      const refineSearch = new RefineSearch(el);
      return refineSearch;
    });
    this.storageButtonManager = new StorageButtonManager({
      keep: new Keep(),
      favorite: new Favorite(),
    });
    this.storageButtonManager.build();
    this.asyncJobOfferAggregations = [...document.querySelectorAll('.js-job-offer-aggregation')].map((el) => {
      const asyncJobOfferAggregation = new AsyncJobOfferAggregation(el, {
        storageButtonManager: this.storageButtonManager,
      });
      return asyncJobOfferAggregation;
    });
    this.collapses = [...document.querySelectorAll('.js-collapse')].map((el) => {
      const collapse = new Collapse(el);
      return collapse;
    });
    this.headRoom = new HeadRoom('#js-header');
    this.footbar = new FootBar('#js-foot-bar', {
      upperLandmark: '.js-foot-bar-upper-landmark',
      lowerLandmark: '.js-foot-bar-lower-landmark',
    });
    this.readMores = [...document.querySelectorAll('.js-read-more')].map((el) => {
      const readMore = new ReadMore(el);
      return readMore;
    });
    this.accordions = [...document.querySelectorAll('.js-accordion')].map((el) => {
      const accordion = new Accordion(el);
      return accordion;
    });
    this.dateOptionsGenerators = [...document.querySelectorAll('.js-date-options-generator')].map((el) => {
      const dateOptionsGenerator = new DateOptionsGenerator(el);
      return dateOptionsGenerator;
    });
    this.dropdown = new Dropdown();
    this.tabs = [...document.querySelectorAll('.js-tab')].map((el) => {
      const $el = $(el);
      const tab = new Tab(el, {
        navActiveClassName: $el.data('tab-nav-active-class-name'),
        contentActiveClassName: $el.data('tab-content-active-class-name'),
      });
      return tab;
    });
    this.gridTab = new GridTab('.js-grid-tab', {
      navActiveClassName: 'c-area-tab__item--active',
    });
    this.addressFillFromPostalCodes = [...document.querySelectorAll('.js-postal-code')].map((postalCode, index) => {
      const city = $('.js-city-select').eq(index)[0];

      return new AddressFillFromPostalCode({
        postalCode,
        prefecture: $('.js-prefecture-select').eq(index)[0],
        city,
        town: $('.js-town-input').eq(index)[0],
        cityOptionGenerator: new CityOptionsGenerator({
          prefecture: $('.js-prefecture-select').eq(index)[0],
          city,
        }),
        done: ({ $prefecture, $city }) => {
          if (!$prefecture.hasClass('js-form-manager__field')) {
            return;
          }
          emitter.emit('formManager:validateFields', {
            fields: [$prefecture, $city],
          });
        },
      });
    });
    this.onetimeDisplaySwitchers = [...document.querySelectorAll('.js-onetime-display-switcher')].map((el) => {
      const onetimeDisplaySwitcher = new OnetimeDisplaySwitcher(el);
      return onetimeDisplaySwitcher;
    });
    this.scrollToTop = new ScrollToTop();
    this.scrollToReadStart = new ScrollToReadStart();
    this.scrollToTarget = new ScrollToTarget();
    this.searchConditionSaveForm = new SearchConditionSaveForm('#js-search-condition-save-form', {
      notificationModal: new NotificationModal({
        modalId: 'notification',
        cookieId: 'search-condition-saved',
      }),
    });
    this.messageForm = new MessageForm({
      form: '#js-message-form',
      appliedNotificationModal: new NotificationModal({ modalId: 'applied-notification', cookieId: 'applied-notification' }),
      refusedScoutNotificationModal: new NotificationModal({ modalId: 'refused-scout-notification', cookieId: 'refused-scout-notification' }),
    });

    const isThisIOS = isIOS();
    this.formManagers = [...document.querySelectorAll('.js-form-manager')].map((form) => {
      const formManager = new FormManager({
        form,
        errorClass: 'u-is-error',
        completedClass: 'js-is-completed',
        $counter: $('.js-form-manager-counter'),
        $total: $('.js-form-manager-total'),
        callback: {
          onFocus: () => {
            // iOS ではキーボード出現時にposition: fixed;の固定フッターが適切な位置に配置されず入力の妨げになりうる挙動をするので、キーボード出現中は固定フッターを非表示にしておく
            if (isThisIOS) {
              $('.js-form-manager-foot-bar').addClass('u-hidden');
            }
          },
          onBlur: () => {
            if (isThisIOS) {
              $('.js-form-manager-foot-bar').removeClass('u-hidden');
            }
          },
          onValidationMessageUpdated: ({ fields }) => {
            const triggers = fields.map((field) => {
              return $(field).closest('.js-accordion__item').find('.js-accordion__item-trigger');
            });
            emitter.emit('accordion:syncHeight', { triggers });
          },
        },
      });

      return formManager;
    });
    this.linkExtender = new LinkExtender();
    this.requiredQualificationByJobCategory = new RequiredQualificationByJobCategory('.js-required-qualification-by-job-category');
    this.renameDocumentModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['rename-resume', 'rename-careersheet'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new RenameDocumentModal({
      modalId: $(el).data('modal-id'),
    }));
    this.deleteDocumentModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['delete-document'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new DeleteDocumentModal({
      modalId: $(el).data('modal-id'),
    }));
    this.selectAttachDocumentMessageModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['select-attach-document-message'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new SelectAttachDocumentMessageModal({
      modalId: $(el).data('modal-id'),
    }));
    this.regionModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['search-region-1', 'search-region-2', 'search-region-3', 'search-region-4', 'search-region-5', 'search-region-6', 'search-region-7'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new RegionModal({
      modalId: $(el).data('modal-id'),
    }));
    this.simpleModals = [
      ...($('.js-modal').filter((_, el) => {
        return [
          'search-facility',
          'search-job-category',
          'contact-confirm',
          'job-offer-summary',
          'no1',
          'job-category-supported',
          'about-private',
          'search-condition-registration',
          'settings-withdrawal',
          'about-bank-code',
          'search-filter',
        ].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new SimpleModal({
      modalId: $(el).data('modal-id'),
    }));
    this.renameSearchConditionModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['rename-search-condition'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new RenameSearchConditionModal({
      modalId: $(el).data('modal-id'),
    }));
    this.deleteSearchConditionModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['delete-search-condition'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new DeleteSearchConditionModal({
      modalId: $(el).data('modal-id'),
    }));
    this.toggles = [...document.querySelectorAll('.js-toggle')].map((wrapper) => {
      return new Toggle({ wrapper });
    });
    this.toaster = new Toaster('.js-toaster');
    this.memberNotification = new MemberNotification('.js-member-notification');
    this.applyJobofferDetailModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['mypage-joboffer-detail-modal', 'mypage-joboffer-detail-disabled-modal', 'mypage-joboffer-detail-info-modal'].includes($(el).data('modal-id'));
      })),
    ].map(el => new ApplyJobOfferDetailModal({
      modalId: $(el).data('modal-id'),
    }));
    this.hideMessageModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['hide-message', 'show-message'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new HideMessageModal({
      modalId: $(el).data('modal-id'),
    }));
    this.indeedEntryModal = new IndeedEntryModal({
      modalId: 'mypage-indeed-entry-modal',
    });
    this.searchStateModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['search-state'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new SearchStateModal({
      modalId: $(el).data('modal-id'),
    }));
    this.inputtedTextCounters = [document.querySelectorAll('.js-inputted-text-counter')].map((counter) => {
      return new InputtedTextCounter(counter);
    });
    this.restartGuidanceManager = new RestartGuidanceManager();
    this.iOSDrumrollOmissionPreventers = [...document.querySelectorAll('.js-ios-drumroll-omission-preventer')].map((el) => {
      const iOSDrumrollOmissionPreventer = new IOSDrumrollOmissionPreventer(el);
      return iOSDrumrollOmissionPreventer;
    });
    this.timezone = new Timezone();
  }

  init = () => {
    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      beforeSend: (xhr, settings) => {
        const parsedUrl = queryString.parseUrl(settings.url);

        if (parsedUrl.url[parsedUrl.url.length - 1] === '/') {
          return;
        }
        if (Object.keys(parsedUrl.query).length > 0) {
          settings.url = `${ parsedUrl.url }/?${ queryString.stringify(parsedUrl.query) }`; // eslint-disable-line no-param-reassign
          return;
        }
        settings.url = `${ parsedUrl.url }/`; // eslint-disable-line no-param-reassign
      },
    });
    smoothscroll.polyfill();
    this.headRoom.init();
    this.footbar.init();
    this.addressFillFromPostalCodes.map(addressFillFromPostalCode => addressFillFromPostalCode.init());
    this.refineSearches.map(refineSearch => refineSearch && refineSearch.init());
    this.collapses.map(collapse => collapse.init());
    this.readMores.map(readMore => readMore.init());
    this.accordions.map(accordion => accordion.init());
    this.dateOptionsGenerators.map(dateOptionsGenerator => dateOptionsGenerator.init());
    this.tabs.map(tab => tab.init());
    this.dropdown.init();
    this.gridTab.init();
    this.onetimeDisplaySwitchers.map(onetimeDisplaySwitcher => onetimeDisplaySwitcher.init());
    this.scrollToTop.init();
    this.scrollToReadStart.init();
    this.scrollToTarget.init();

    // NOTE: formManagerのinit処理を待つ（registerSchemaの処理を待つ必要がある）
    Promise.all(this.formManagers.map(formManager => formManager.init())).then(() => {
      this.messageForm.init(this.formManagers);
    });

    this.searchConditionSaveForm.init();
    this.linkExtender.init();
    this.asyncJobOfferAggregations.reduce((accumulator, current) => {
      return accumulator.then(() => {
        return current.render();
      });
    }, Promise.resolve());
    this.requiredQualificationByJobCategory.init();
    this.renameDocumentModals.map(renameDocumentModal => renameDocumentModal.init());
    this.deleteDocumentModals.map(deleteDocumentModal => deleteDocumentModal.init());
    this.selectAttachDocumentMessageModals.map(selectAttachDocumentMessageModal => selectAttachDocumentMessageModal.init());
    this.regionModals.map(regionModal => regionModal.init());
    this.simpleModals.map(simpleModal => simpleModal.init());
    this.renameSearchConditionModals.map(renameSearchConditionModal => renameSearchConditionModal.init());
    this.deleteSearchConditionModals.map(deleteSearchConditionModal => deleteSearchConditionModal.init());
    this.searchStateModals.map(searchStateModal => searchStateModal.init());
    this.hideMessageModals.map(hideMessageModal => hideMessageModal.init());
    this.toggles.map(toggle => toggle.init());
    this.toaster.hide();

    $('.js-desired-job-category-checkbox-synchronizer').on('change', 'input:checkbox', e => CheckboxSynchronizer.sync(e.target, {
      type: 'value',
      form: $(e.target).closest('.js-desired-job-category-checkbox-synchronizer')[0],
    }));
    this.memberNotification.init();
    this.applyJobofferDetailModals.map(applyJobOfferModal => applyJobOfferModal.init());
    this.indeedEntryModal.init();
    this.inputtedTextCounters.map(counter => counter.init());
    this.restartGuidanceManager.init();
    this.iOSDrumrollOmissionPreventers.map(iOSDrumrollOmissionPreventer => iOSDrumrollOmissionPreventer.init());
    this.timezone.init();

    const pageViewID = $('.js-page-view-id').data('page-view-id');

    const route = new Promise((resolve) => {
      switch (pageViewID) {
        case 'job_offers/index':
        case 'job_offers/employment':
        case 'job_offers/feature':
        case 'job_offers/employment_feature':
        case 'job_offers/prefecture':
        case 'job_offers/prefecture_employment':
        case 'job_offers/prefecture_feature':
        case 'job_offers/prefecture_employment_feature':
        case 'facilities/search':
          import('./pages/job_offer_search/static-search' /* webpackChunkName: './pages/job_offer_search/static-search' */).then(({ StaticJobOfferSearch }) => {
            new StaticJobOfferSearch().init();
            resolve();
          });
          break;
        case 'job_offers/city':
          import('./pages/job_offer_search/city' /* webpackChunkName: './pages/job_offer_search/city' */).then(({ JobOfferSearchCity }) => {
            new JobOfferSearchCity().init();
            resolve();
          });
          break;
        case 'job_offers/detail':
        case 'job_offers/detail_map':
          import('./pages/job_offer_detail' /* webpackChunkName: './pages/job_offer_detail' */).then(({ JobOfferDetail }) => {
            new JobOfferDetail().init();
            resolve();
          });
          break;
        case 'job_offers/map_search':
          import('./search-by-map' /* webpackChunkName: './search-by-map' */).then(({ SearchByMap }) => {
            new SearchByMap({
              storageButtonManager: this.storageButtonManager,
            }).init();
            resolve();
          });
          break;
        case 'job_offers/search':
          import('./pages/job_offer_search/index' /* webpackChunkName: './pages/job_offer_search/index' */).then(({ JobOfferSearch }) => {
            new JobOfferSearch().init();
            resolve();
          });
          break;
        case 'facilities/detail':
          import('./pages/facility_detail' /* webpackChunkName: './pages/facility_detail' */).then(({ FacilityDetail }) => {
            new FacilityDetail().init();
            resolve();
          });
          break;
        case 'members/index':
          import('./members-index' /* webpackChunkName: './members-index' */).then(({ MembersIndex }) => {
            new MembersIndex().init();
            resolve();
          });
          break;
        case 'members/messages':
        case 'members/messages/hidden':
          import('./members-messages' /* webpackChunkName: './message-swipe' */).then(({ MembersMessages }) => {
            new MembersMessages().init();
            resolve();
          });
          break;
        case 'profiles/index':
        case 'profiles/edit':
        case 'profile/additional/desired_cities/index':
          import('./members-profiles' /* webpackChunkName: './members-profiles' */).then(({ MembersProfiles }) => {
            new MembersProfiles({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'settings/index':
        case 'settings/edit_job_match_mail':
          import('./members-settings' /* webpackChunkName: './members-settings' */).then(({ MembersSettings }) => {
            new MembersSettings().init();
            resolve();
          });
          break;
        case 'static_pages/banner':
          import('./banner' /* webpackChunkName: './banner' */).then(({ Banner }) => {
            new Banner().init();
            resolve();
          });
          break;
        case 'apply_job_offers/complete':
          import('./pages/apply_job_offer/index' /* webpackChunkName: './pages/apply_job_offer/index' */)
            .then(({ ApplyJobOffer }) => {
              new ApplyJobOffer().init();
              resolve();
            });
          break;
        case 'profile/additional/member_qualifications/index':
          import('./pages/additional_member_qualification/index' /* webpackChunkName: './pages/additional_member_qualification/index' */).then(({ AdditionalMemberQualification }) => {
            new AdditionalMemberQualification().init();
            resolve();
          });
          break;
        case 'members/resumes':
          import('./pages/resume/index' /* webpackChunkName: './pages/resume/index' */).then(({ Resume }) => {
            new Resume().init();
            resolve();
          });
          break;
        case 'members/resumes/new':
          import('./pages/resume_new/index' /* webpackChunkName: './pages/resume_new/index' */).then(({ ResumeNew }) => {
            new ResumeNew().init();
            resolve();
          });
          break;
        case 'members/messages/show':
          import('./pages/message_show/index' /* webpackChunkName: './pages/message_show/index' */).then(({ MessageShow }) => {
            new MessageShow().init();
            resolve();
          });
          break;
        case 'applications/new':
          import('./pages/application_new/index' /* webpackChunkName: './pages/application_new/index' */).then(({ ApplicationNew }) => {
            new ApplicationNew({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/sign_up':
          import('./pages/members_signup/index' /* webpackChunkName: './pages/members_signup/index' */).then(({ MembersSignUp }) => {
            new MembersSignUp({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/job_offers/favorite':
          import('./pages/members_favorite_job_offers/index' /* webpackChunkName: './pages/members_favorite_job_offers/index' */).then(({ MembersFavoriteJobOffersIndex }) => {
            new MembersFavoriteJobOffersIndex().init();
            resolve();
          });
          break;
        case 'history':
          import('./pages/history/index' /* webpackChunkName: './pages/history/index' */).then(({ History }) => {
            new History().init();
            resolve();
          });
          break;
        case 'profile/additional/desired_job_categories/index':
          import('./pages/additional_completed_count/job_category' /* webpackChunkName: './pages/additional_completed_count/job_category' */).then(({ CompletedCountAdditionalJobCategory }) => {
            new CompletedCountAdditionalJobCategory().init();
            resolve();
          });
          break;
        case 'lp/dental':
          import('./pages/landing_pages/index' /* webpackChunkName: './pages/landing_pages/index' */).then(({ DentalLandingPage }) => {
            new DentalLandingPage({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        default:
          resolve();
          break;
      }
    });

    route.then(() => {
      LazyImage.load();
    });
  }
}

$(() => {
  const app = new App();

  app.init();
});
